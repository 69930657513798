.iconaccount_6 {
  width: 34px;
  height: 34px;
  margin: 52px 89px 14px 20px;
  object-fit: contain;
}
.iconlettre_mission {
  width: 34px;
  height: 34px;
  margin: 52px 89px 14px;
  object-fit: contain;
}
.icontasks {
  width: 34px;
  height: 34px;
  margin: 52px 65px 14px;
  object-fit: contain;
}
.iconinvoice {
  width: 34px;
  height: 34px;
  margin: 52px 89px 14px ;
  object-fit: contain;
}
.iconstats {
  width: 34px;
  height: 34px;
  margin: 52px 49px 14px 89px;
  object-fit: contain;
}
.iconfile {
  width: 34px;
  height: 34px;
  margin: 40px 89px 14px 20px;
  object-fit: contain;
}
.iconportefeuille {
  width: 34px;
  height: 34px;
  margin: 40px 89px 14px;
  object-fit: contain;
}
.iconprocess {
  width: 34px;
  height: 34px;
  margin: 40px 89px 14px;
  object-fit: contain;
}
.iconcabinet_et_moi {
  width: 34px;
  height: 34px;
  margin: 40px 80px 14px;
  object-fit: contain;
}
.iconsign {
  width: 34px;
  height: 34px;
  margin: 40px 49px 14px 89px;
  object-fit: contain;
}
.Vos-clients-et-prosp {
  width: 120px;
  height: 36px;
  margin: 14px 40px 40px -19px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.Les-lettres-de-mission {
  width: 120px;
  height: 36px;
  margin: 14px 40px 40px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.Les-obligations-et-l {
  width: 130px;
  height: 36px;
  margin: 14px 40px 40px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.La-facturation-et-le {
  width: 132px;
  height: 36px;
  margin: 14px 40px 40px 22px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.Une-analyse-BI-des-d {
  width: 132px;
  height: 36px;
  margin: 14px 0 40px 40px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.La-gestion-des-docum {
  width: 120px;
  height: 54px;
  margin: 14px 40px 0 -19px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.Collaboration-entrecol {
  width: 132px;
  height: 36px;
  margin: 14px 40px 18px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.Suivi-de-procdure-i {
  width: 132px;
  height: 36px;
  margin: 14px 40px 18px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.Collaboration-entrecl {
  width: 132px;
  height: 36px;
  margin: 14px 36px 18px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}
.Suivi-ralisation-do {
  width: 132px;
  height: 54px;
  margin: 14px 0 0 40px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #944a98;
}